<template>
  <app-event event-id="kia-2020" :mapping="mapping" auth="links-only">
    <template v-slot:logo>
      <v-img :src="require('./assets/G&W.png')" class="mb-4 mt-4 ml-4 mr-4" />
    </template>
  </app-event>
</template>

<script>
// import EventLayout from "@/layouts/advent-one/Index.vue";
import AppEvent from "@/components/event/Event.vue";
import KiaMagazine from "./Magazine";
import KiaCalendar from "./Calendar";
import KiaGallery from "./Gallery";
import KiaStories from "./Stories";

export default {
  name: "AppPageEvent",
  components: {
    // EventLayout,
    AppEvent,
  },
  data() {
    return {
      mapping: {
        "kia-magazine": KiaMagazine,
        "kia-gallery": KiaGallery,
        "kia-stories": KiaStories,
        calendar: KiaCalendar,
      },
    };
  },
};
</script>