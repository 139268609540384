import { mapGetters } from 'vuex';
import keyBy from 'lodash/keyBy';
import userMixin from './eventUser';

export default {
  mixins: [userMixin],
  computed: {
    ...mapGetters({
      forms: 'event/forms',
    }),
    formsById() {
      return keyBy(this.forms, 'id');
    },
  },
};
