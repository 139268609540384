var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('calendar',{attrs:{"min":_vm.params.min,"max":_vm.params.max,"dates":_vm.dates,"clickable-dates":_vm.dates},on:{"select-date":_vm.onDateSelect}}),(_vm.selectedForm)?_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mobile,"max-width":_vm.$vuetify.breakpoint.mobile ? null : 600},model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}},[_c('div',{staticClass:"secondary darken-2",class:{ 'd-flex': !_vm.$vuetify.breakpoint.mobile }},[_c('v-card',[(_vm.selectedForm.video)?_c('video-player',_vm._b({},'video-player',_vm.selectedForm.video,false)):(_vm.selectedForm.image)?_c('v-img',{staticClass:"primary",attrs:{"src":_vm.selectedForm.image}}):_vm._e(),(_vm.selectedForm.title)?_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.selectedForm.title)+" ")]):_vm._e(),(_vm.selectedForm.description)?_c('v-card-text',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.selectedForm.description.replace(/\n/gim, '<br/>'))}})]):_vm._e(),_c('v-fade-transition',[(
              _vm.selectedFormUserSubmittedData &&
              _vm.selectedForm.fields &&
              _vm.selectedForm.fields.length > 0
            )?_c('v-card-text',[_c('v-alert',{attrs:{"type":"success","text":""}},[_vm._v("Заявка успешно отправлена")])],1):_vm._e()],1),(
            _vm.selectedForm.limit &&
            !_vm.selectedFormUserSubmittedData &&
            _vm.selectedForm.fields &&
            _vm.selectedForm.fields.length > 0
          )?_c('v-card-text',[_c('v-alert',{attrs:{"text":"","type":_vm.selectedForm.limit - _vm.selectedForm.usage > 0 ? 'info' : 'warning'}},[_vm._v(_vm._s(_vm.selectedForm.limit - _vm.selectedForm.usage > 0 ? ("Мест осталось: " + (_vm.selectedForm.limit - _vm.selectedForm.usage)) : "Регистрация завершена"))])],1):_vm._e(),(
            !_vm.selectedForm.limit || _vm.selectedForm.limit - _vm.selectedForm.usage > 0
          )?_c('v-card-text',_vm._l((_vm.selectedForm.fields),function(field){return _c('v-text-field',{key:("input-" + (field.name)),attrs:{"label":field.label},model:{value:(_vm.formData[field.name]),callback:function ($$v) {_vm.$set(_vm.formData, field.name, $$v)},expression:"formData[field.name]"}})}),1):_vm._e(),(
            _vm.selectedForm.fields &&
            _vm.selectedForm.fields.length > 0 &&
            (!_vm.selectedForm.limit ||
              _vm.selectedForm.limit - _vm.selectedForm.usage > 0)
          )?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.formDialog = false}}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_vm.selectedFormIsValid},on:{"click":_vm.onFormSubmit}},[_vm._v(" "+_vm._s(_vm.selectedFormUserSubmittedData ? "Обновить заявку" : "Записаться")+" ")]),_c('v-spacer')],1):_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.formDialog = false}}},[_vm._v(" Закрыть ")])],1),_c('v-snackbar',{attrs:{"value":true,"color":_vm.messageStatus},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.message))])],1)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }