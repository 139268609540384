<template>
  <v-row>
    <v-col
      :cols="6"
      v-for="item in items"
      md="6"
      sm="12"
      xs="12"
      :key="`gallery-item-${item.id}`"
    >
      <stories-item
        :text="item.text"
        :author="galleryShowAuthor ? item.userName : null"
        :id="item.id"
        :likeable="isLikeable"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import StoriesItem from "./StoriesItem";

export default {
  name: "KiaStories",
  props: ["params", "item-id"],
  components: {
    StoriesItem,
  },
  computed: {
    ...mapGetters({
      event: "event/event",
    }),
    galleryName() {
      return this.params.name;
    },
    galleryShowAuthor() {
      return !!this.params.showAuthor;
    },
    items() {
      return _.map(
        _.get(this.event, `meta.${this.galleryName}`) || [],
        (item) => {
          return item;
        }
      );
    },
    isLikeable() {
      return this.params.likeable === true;
    },
  },
};
</script>

<style scoped>
.gallery-title {
  word-break: normal;
}
</style>