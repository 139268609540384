<template>
  <div class="kia-wrapper">
    <div>
      <v-card
        class="ma-4"
        v-for="(issue, index) in issues"
        :key="index"
        elevation="4"
        style="cursor: pointer"
        @click="onSelectIssue(index)"
        :max-width="320"
      >
        <v-img :src="issue.cover || issue.images[0]" :height="300" />
        <v-card-title>{{ issue.title }}</v-card-title>
        <v-card-subtitle v-if="issue.subtitle">{{
          issue.subtitle
        }}</v-card-subtitle>
      </v-card>
    </div>
    <v-fade-transition>
      <div
        class="kia-popup"
        v-if="popupOpen && selectedIssue"
        :class="{ 'is-opened': popupOpen }"
      >
        <div class="kia-popup__inner">
          <div class="kia-book-controls">
            <button class="kia-book-button is-prev">
              <!-- <div
                class="kia-book-button__title"
                v-if="!$vuetify.breakpoint.mobile"
              >
                Назад
              </div> -->
              <v-btn
                fab
                color="primary"
                class="kia-book-button__title"
                style="opacity: 0.5"
                dark
                small
              >
                <v-icon dark> mdi-arrow-left </v-icon>
              </v-btn>
            </button>
            <button class="kia-book-button is-next">
              <!-- <div
                class="kia-book-button__title"
                v-if="!$vuetify.breakpoint.mobile"
              >
                Вперед
              </div> -->
              <v-btn
                fab
                color="primary"
                class="kia-book-button__title"
                style="opacity: 0.5"
                dark
                small
              >
                <v-icon dark> mdi-arrow-right </v-icon>
              </v-btn>
            </button>
          </div>
          <fw-bookblock
            class="bb-bookblock kia-book"
            ref="bookBlock"
            :options="bookblockOptions"
          >
            <div
              v-for="image in selectedIssue.images"
              :key="image"
              style="width: 100%; background-color: white"
              class="bb-item"
            >
              <img :src="image" style="width: 100%" />
            </div>
          </fw-bookblock>
          <v-btn
            fab
            color="secondary"
            dark
            small
            class="kia-popup-close"
            @click="onPopupClose"
          >
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </div>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import { FwBookblock } from "vue-turnjs";
import "vue-turnjs/dist/vue-turnjs.esm.css";
import _ from "lodash";

export default {
  name: "KIA2020Magazine",
  props: ["params", "item-id"],
  components: {
    FwBookblock,
  },
  data() {
    return {
      slidesCount: 13,
      popupOpen: false,
      selectedIssueIndex: null,
      currentPage: 0,
      options: {
        display: "double",
        acceleration: true,
        elevation: 50,
      },
      bookblockOptions: {
        speed: 800,
        shadowSides: 0.8,
        shadowFlip: 0.7,
        autoplay: false,
        orientation: "vertical",
        nextEl: ".kia-book-button.is-next",
        prevEl: ".kia-book-button.is-prev",
        // onBeforeFlip: this.changeKiaButton,
        // onEndFlip: this.changeKiaButton,
      },
    };
  },
  computed: {
    issues() {
      return _.get(this, "params.issues") || [];
    },
    selectedIssue() {
      return this.selectedIssueIndex == null
        ? null
        : this.issues[this.selectedIssueIndex];
    },
  },
  methods: {
    onPopupOpen() {
      if (!this.popupOpen) {
        this.popupOpen = true;
      }
    },
    onPopupClose() {
      this.popupOpen = false;
    },
    changeKiaButton(page) {
      this.currentPage = page;
      // console.log(this.currentPage);
    },
    onSelectIssue(index) {
      this.selectedIssueIndex = index;
      this.popupOpen = true;
    },
  },
};
</script>

<style lang="scss">
.kia-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: auto;
  padding-top: 50px;
  padding-bottom: 150px;
  padding-left: 12%;
  padding-right: 12%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;

  &.is-opened {
    opacity: 1;
    pointer-events: all;
  }

  &__inner {
    position: relative;
    margin: auto;
    width: 100%;
    padding-bottom: 56.25%;
  }
}

.kia-book {
  position: absolute;
  width: 100%;
  height: 100%;
}

.kia-book-controls {
  position: absolute;
  z-index: 200;
  display: flex;
  width: 100%;
  height: 100%;
}

.kia-book-button {
  $this: &;
  position: relative;
  width: 50%;
  outline: none;

  &.is-stop {
    cursor: not-allowed !important;
    pointer-events: none;
  }

  &.is-prev {
    margin-right: auto;
    cursor: w-resize;

    #{$this}__title {
      left: 30px;
    }
  }
  &.is-next {
    margin-left: auto;
    cursor: e-resize;

    #{$this}__title {
      right: 30px;
    }
  }

  &:hover {
    #{$this}__title {
      opacity: 1;
    }
  }

  &__title {
    position: absolute;
    bottom: 30px;
    opacity: 0;
    border-radius: 4px;
    padding: 8px 8px;
    font-weight: 400;
    color: #fff;
    text-shadow: 1px 1px 2px black;
    background-color: #ff000066;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.2);
    transition: opacity 0.2s;
  }
}

.kia-popup-close {
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 300;
}
</style>
